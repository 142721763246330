import React from 'react';
import reactStringReplace from 'react-string-replace';

class Medicine extends React.Component {
    renderInventory = (availableQuantity) => {
        if (availableQuantity > 0) {
            return (
                <span className='inventory-badge badge rounded-pill bg-available'>
                    Disponible
                </span>
            );
        } else {
            return (
                <span className='inventory-badge badge rounded-pill bg-danger'>
                    No Disponible
                </span>
            );
        }
    };

    render() {
        const { name, description, inventory, query } = this.props.details;
        const highlightedName = reactStringReplace(name, query, (match, i) => (
            <span className='highlight'>{match}</span>
        ));
        // const highlightedDescription = reactStringReplace(
        //     description,
        //     query,
        //     (match, i) => <span className='highlight'>{match}</span>
        // );

        return (
            <div key={this.props.details.id} className='card medicine shadow-sm p-md-2 border-0'>
                <div className='card-body'>
                    <div className='medicine-header'>
                        <h5 className='card-title'>{highlightedName}</h5>
                        <p className='card-text'>{description}</p>
                    </div>
                    <div>
                        {this.renderInventory(inventory.availableQuantity)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Medicine;
