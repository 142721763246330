import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NotFound = () => (
    <Container fluid className="main-container">
        <Row className='main-row'>
            <Col className='main-col'>
                <h1 className='title mb-4'>Ups!</h1>
                <p className="lead mb-0">No se pudo hallar la página que intentó visitar.</p>
                <p className="lead">Por favor, regrese a una página válida.</p>
            </Col>
        </Row>
    </Container>
);

export default NotFound;