import React from 'react';
import axios from 'axios';

class SearchForm extends React.Component {
    inputRef = React.createRef();

    searchMedicine = async (event) => {
        if (event) {
            event.preventDefault();
            // console.log(event);
        }
        const query = this.inputRef.current.value;

        if (query === '') {
            this.props.loadMedicines([]);
            this.props.setInputFieldState(false);
            return;
        } else {
            this.props.setInputFieldState(true);
        }

        const authHeader = `${process.env.REACT_APP_ALEGRA_EMAIL}:${process.env.REACT_APP_ALEGRA_TOKEN}`;
        const encodedAuthHeader =
            'Basic ' + Buffer.from(authHeader).toString('base64');

        const config = {
            headers: {
                Authorization: encodedAuthHeader,
                'Content-Type': 'application/json',
            },
            params: {
                query,
                status: 'active'
            },
        };

        try {
            const response = await axios.get(
                'https://api.alegra.com/api/v1/items/',
                config
            );
            const matches = this.findMatches(query, response.data);
            this.props.loadMedicines(matches);
        } catch (error) {
            console.error(error);
        }
    };

    findMatches = (wordToMatch, medicines) => {
        const availableMeds = medicines.filter((medicine) => {
            if (medicine.inventory === undefined) {
                return false;
            }
            // else if (medicine.inventory.availableQuantity <= 0) {
            //     return false;
            // }
            return true;
        });

        const matches = availableMeds.filter((medicine) => {
            const regex = new RegExp(wordToMatch, 'gi');
            return (
                medicine.name.match(regex) || medicine.reference.match(regex)
            );
        });

        matches.sort((a, b) => {
            if (a.inventory.availableQuantity > 0 && b.inventory.availableQuantity <= 0) {
                return -1;
            } else if (a.inventory.availableQuantity <= 0 && b.inventory.availableQuantity > 0) {
                return 1;
            } else {
                return 0;
            }
        });

        return matches.map((medicine) => {
            medicine.query = wordToMatch;
            return medicine;
        });
    };

    render() {
        return (
            <form
                className='search-form'
                onSubmit={(event) => {
                    // console.log('submit event fired!');
                    this.searchMedicine(event);
                }}
            >
                <input
                    className='search-input form-control'
                    ref={this.inputRef}
                    type='text'
                    placeholder='Ingrese el nombre del medicamento que busca'
                    onKeyUp={(event) => {
                        // console.log('keyUp event fired!');
                        this.searchMedicine(event);
                    }}
                />
                <button className='btn' type='submit'>
                    Buscar
                </button>
            </form>
        );
    }
}

export default SearchForm;
