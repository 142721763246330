import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SearchForm from './SearchForm';
import Medicine from './Medicine';

class App extends React.Component {
    state = {
        medicines: [],
        inputFieldIsActive: false
    };

    loadMedicines = (medicines) => {
        this.setState({ medicines });
    };

    getResultMessage = () => {
        if (this.state.medicines.length > 0) {
            return `Mostrando ${this.state.medicines.length} resultados`;
        } else if (this.state.medicines.length <= 0 && this.state.inputFieldIsActive) {
            return 'No se obtuvieron resultados'
        } else {
            return '';
        }
    }

    setInputFieldState = (inputFieldState) => {
        this.setState({ inputFieldIsActive: inputFieldState });
    }

    render() {
        return (
            <Container fluid className='main-container'>
                <Row className='main-row'>
                    <Col xs={12} className='nav'>
                        <img className='logo img-fluid' src="./logo.svg" alt="HGH Pharma" />
                    </Col>
                    <Col xs={12} md={8} lg={6} className='main-col'>
                        <h1 className='title'>Consulta y Disponibilidad <br />de Medicamentos</h1>
                        <SearchForm loadMedicines={this.loadMedicines} setInputFieldState={this.setInputFieldState}/>
                        <p className='result-message'>
                            {this.getResultMessage()}
                        </p>
                        <ul className='medicine-list'>
                            {this.state.medicines.map((medicine, index) => (
                                <Medicine
                                    key={medicine.id}
                                    index={index}
                                    details={medicine}
                                />
                            ))}
                        </ul>
                    </Col>
                    <Col xs={12} className='footer'>
                        <p className='text-white mb-0'>
                            <span className='text-light-blue'>
                                Copyright &copy; 2020 HGH Pharma All rights reserved
                            </span>
                            <span className="d-none d-md-inline mx-1">|</span>
                            <span className='text-pink'>
                                Design and conceptualization by AR Studio, Suite Gráfica
                            </span>
                            <span className="d-none d-md-inline mx-1">|</span>
                            <a className='design-link' target="_blank" rel="noreferrer" href="https://www.arstudio.design">www.arstudio.design</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default App;
